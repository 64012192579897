@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

/* .contact__details{
    
    position: absolute;
    top: 300px;
    margin-left: 10%;
} */

.contact__address__location {
  display: flex;
}

.contact__address__location > p {
  margin-left: 4%;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
}

.contact__support {
  display: flex;
}

.contact__mail {
  display: flex;
}

.contact__mail > p {
  margin-left: 4%;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
}

.contact__support > p {
  margin-left: 8%;
  color: #eb1c24;
  font-family: "Poppins", sans-serif;
}

.hr__contact {
  border: solid #ffffff;
  border-radius: 15%;
  border-bottom: none;
  width: 40%;
  opacity: 1 !important;
}

.contact__address {
  border-radius: 25px 25px 0px 0px;
  background: #eb1c24;
  padding: 20px;
  width: 400px;
  height: 200px;
}

.contact__support__details {
  border-radius: 0px 0px 0px 0px;
  background: #ffffff;
  padding: 20px;
  width: 450px;
  height: 100px;
  position: relative;
  right: 25px;
  box-shadow: 0px 0px 3px;
}

.contact__mail__details {
  border-radius: 0px 0px 25px 25px;
  background: #eb1c24;
  padding: 20px;
  width: 400px;
  height: 100px;
}

.contact__address > h4 {
  color: #ffffff;
  font-family: "Poppins", sans-serif;
}

.room__icon {
  color: #ffffff !important;
  font-size: 2.5rem !important;
}

.headset__mic {
  color: #eb1c24 !important;
  font-size: 2.5rem !important;
}

@media (max-width: 768px) {
  .contact__support__details {
    width: 430px !important;
  }
}
