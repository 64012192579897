@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;1,700&display=swap");

h4,
p {
  font-family: "Poppins", sans-serif;
}
.elite__services__values > h3 {
  text-align: center;
  font-family: "Poppins", sans-serif;
}

.elite__services__values > h1 {
  text-align: center;
  color: #1f1f1f;
  font-family: "Poppins", sans-serif;
}

.service__value {
  text-align: center;
  color: #413b3b;
}

.elite__logistics__services {
  margin-top: 5%;
  /* background: linear-gradient(
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    url("./eliipse-circles-red.png");
  background-repeat: no-repeat;
  background-size: contain; */
}

.logistics__service {
  height: 350px;
  width: 100%;
  border-radius: 0.5rem;
  margin: 0 auto;
  display: flex;
}

.flex-style-row {
    display: flex;
  align-items: center;
  justify-content: center;
}

.airline__service__col {
  padding: 5%;
  line-height: 2;
  text-align: justify;
}


.airline__service__col h4 {
  padding-bottom: 1%;
  line-height: 2;
}

.roadways__service {
  margin-top: 2%;
}

.service__line {
  display: none;
  color: #eb1c24;
  opacity: 1;
}

@media (max-width: 768px) {
  .service__value {
    text-align: justify !important;
    margin-bottom: 10%;
  }
  .logistics__service {
    height: auto !important;
  }
  .service__line {
    display: block;
  }
}


.airline__service__col h4 {
  text-transform: capitalize;
  font-weight: 700;
}

@media screen and (max-width: 992px) {
  .logistics__service {
    width: 100%;
  }
}