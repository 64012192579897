.scroll__to__top{
    position: fixed;
  bottom: 0.5rem;
  right: 0.5rem;
  animation: fadeIn 700ms ease-in-out 1s both;
  cursor: pointer;
}

.go__top__button{
    background-color: #eb1c24;
    color: #FFF;
    font-size: 30px !important;
}