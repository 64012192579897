@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;500;700;800;900&display=swap");

.subscribe {
  margin-top: 5%;
  margin-bottom: 5%;
  padding: 0 75px;
}
.flex-style-row {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.get__in__touch__head {
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("bg_touch.png"),
    linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  background-blend-mode: overlay;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 33vw;
}

a {
  text-decoration: none !important;
}

.talk {
  color: #ffffff;
  text-align: center;
  font-weight: 800;
  font-size: 1.75rem;
}
.get-in-h1 {
  color: #ffffff;
  font-weight: 800;
  text-align: center;
  line-height: 1.5;
  text-transform: uppercase;
}

.red-hr {
  width: 20%;
  height: 4px !important;
  color: #eb1c24;
  background-color: #eb1c24;
  opacity: 1;
  margin: 0 0 2rem;
}

.col-inner {
  color: #ffffff;
  background: #eb1c24;
  padding: 2rem;
  width: 80%;
}

.col-inner p {
  color: #dddddd;
  width: 90%;
}
/* .get__in__touch{
    background-image: url('bg_touch.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 70vh;
} */

.email__box {
  text-align: center;
  margin-left: 50px;
  margin-right: 50px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  background-color: #ffffff;
  color: #413b3b;
  padding: 20px;
  position: relative;
  top: -27vh;
}

.email__sub {
  width: 100%;
  padding: 10px;
  border: none;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.2);
}

.email__sub::placeholder {
  color: #cccccc !important;
  opacity: 1;
}

.get-in-touch-btn {
  display: block !important;
  padding: 16px 24px !important;
  border-radius: 30px;
  color: #ffffff !important;
  border-radius: 3rem !important;
  font-size: 1rem !important;
  border-color: #ffffff !important;
}

.get-in-touch-btn:hover {
  color: black !important;
  background: white !important;
}

.email-svg {
  height: 25px;
  width: auto;
}

.svg-col {
  height: 44px;
  background: rgba(0, 0, 0, 0.2);
}

.email__box p {
  padding: 3% 10%;
}
/*New layout */

.email__elite__background {
  background-image: url("bg_touch.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 50vh;
  -webkit-filter: grayscale(40%); /* Safari 6.0 - 9.0 */
  filter: grayscale(40%);
  transform: skewX(-11deg);
}

.elite__background__text {
  color: #ffffff;
  margin-left: 8%;
  padding-top: 5%;
  font-weight: bold;
}

@media (max-width: 768px) {
  .email__get__in__touch {
    width: 70% !important;
  }
  .subscribe {
    margin-bottom: 15% !important;
  }
  .email__sub {
    width: 100%;
  }
}


@media screen and (max-width: 992px) {
.get__in__touch__head {
  height: 66.67vh;
}

}