@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

.form {
  margin-top: 5%;
  margin-bottom: 7.5%;
}

.phone__msg__icon {
  font-size: 3rem !important;
}

.form__head {
  display: flex;
}

.form__text {
  margin-left: 4%;
  font-family: "Poppins", sans-serif;
}

.form__text > h6 {
  font-weight: bold;
  color: #1f1f1f;
}

.form__text > p {
  color: #413b3b;
}

.contact__field {
  margin-top: 5%;
  width: 100%;
  padding: 10px;
  border-color: transparent;
  border-bottom: 2px solid #808080;
  border-radius: 5px;
  color: #413b3b;
}

.contact__field__number {
  margin-top: 5%;
  width: 100%;
  padding: 10px;
  border-color: transparent;
  border-bottom: 2px solid #808080;
  border-radius: 5px;
  color: #413b3b;
}

.contact__field__text {
  margin-top: 5%;
  width: 100%;
  padding: 10px;
  border-color: transparent;
  border-bottom: 2px solid #808080;
  border-radius: 5px;
  color: #413b3b;
  height: 125px;
  resize: none;
}

.contact__field__email {
  margin-top: 5%;
  width: 100%;
  padding: 10px;
  border-color: transparent;
  border-bottom: 2px solid #808080;
  border-radius: 5px;
  color: #413b3b;
}

.contact__button {
  background-color: #eb1c24;
  border: none;
  color: white;
  padding: 12px 40px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 6%;
  /* margin-left: 50%; */
}

/* .form__error{
    color:#eb1c24;
} */
/* 
.gmail-left {
  background: linear-gradient(
      rgba(255, 255, 255, 0.85),
      rgba(255, 255, 255, 0.85)
    ),
    url("./eliipse-circles-red-half-left.png");
  background-repeat: no-repeat;
} */