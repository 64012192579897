@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;500;700;800;900&display=swap");

h6,
h3,
h5,
h4,
p {
  font-family: "Poppins", sans-serif;
}

.flex-style {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

/* .about-bg {
  background: linear-gradient(
      rgba(255, 255, 255, 0.85),
      rgba(255, 255, 255, 0.85)
    ),
    url("./eliipse-circles-red.png");
  background-repeat: no-repeat;
} */



.elite__aboutus > h3 {
  text-align: center;
  color: #eb1c24;
  font-family: "Poppins", sans-serif;
}

.elite__aboutus > h1 {
  text-align: center;
  color: #1f1f1f;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
}

.elite__aboutus__para {
  text-align: justify;
  color: #413b3b;
  font-family: "Poppins", sans-serif;
  padding: 2% 10%;
  font-weight: 500;
  font-size: 1.1rem;
}

.elite__vission__mission {
  margin-top: 5%;
}

.elite__vision {
  padding: 15px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  background-color: whitesmoke;
  margin-right: -25px;
  height: 250px;
}

.elite__mission {
  padding: 15px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  background-color: lightgray;
  height: 250px;
}

.elite__vision > h5 {
  font-weight: bold;
  color: #1f1f1f;
}

.elite__vision > p {
  color: #413b3b;
}

.elite__mission > h5 {
  font-weight: bold;
  color: #1f1f1f;
}

.elite__mission > p {
  color: #413b3b;
}

.elite__about__explore {
  padding: 15px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  background-color: #eb1c24;
  color: #ffffff;
  margin-left: -25px;
  height: 250px;
}

.elite__about__explore__button {
  margin-top: 15px !important;
  margin-left: 10px !important;
  margin-bottom: 15px !important;
  color: #ffffff;
  padding: 15px !important;
  border-radius: 30px !important;
  background-color: #eb1c24 !important;
  border-color: #ffffff !important;
}

.elite__about__explore__button:hover {
  background-color: #eb1c24 !important;
}

.elite__about__explore__para {
  margin-top: 10%;
}

.elite__button__explore {
  margin-bottom: 8%;
}

.about__details__img {
  height: 300px;
  border-style: solid;
  border-top-color: white;
  border-right-color: white;
  border-bottom-color: #eb1c24;
  border-left-color: #eb1c24;
  border-width: 1rem;
}

.about__details__img__sec {
  height: 300px;
  border-style: solid;
  border-top-color: #eb1c24;
  border-right-color: #eb1c24;
  border-bottom-color: white;
  border-left-color: white;
  border-width: 1rem;
}

.about__details {
  margin-top: 5%;
}

.about__details__row {
  margin-top: 5%;
}

.text__justify {
  text-align: justify;
}

.testimonials {
  margin-top: 5%;
}

.testimonials__box > h2 {
  font-weight: bold;
  color: #ffffff;
}

.testimonials__box > h4 {
  color: #eb1c24;
}

.testimonials__box > h5 {
  color: #ffffff;
}

.hr__testimonials {
  border: solid #eb1c24;
  border-radius: 15%;
  border-bottom: none;
  width: 30%;
  opacity: 1 !important;
}

.testimonials__box {
  background-color: #1f1f1f;
  width: auto;
  padding: 5rem;
  border-bottom-right-radius: 50%;
}

.testimonial__carousel {
  top: 25px !important;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  padding: 15px;
}

.testimonial__content {
  color: #413b3b !important;
  padding: 0.5rem 2rem 2rem 1rem;
}

.testimonial__author {
  color: #413b3b !important;
  position: relative !important;
  text-align: center !important;
  top: 0 !important;
}

.carousel-indicators {
  display: none !important;
}

.testimonial__avatar {
  margin-left: auto;
  margin-right: auto;
}

.about__hr__tag {
  color: #eb1c24;
  opacity: 1;
  margin-top: 5%;
  display: none;
}

@media (max-width: 768px) {
  .elite__aboutus__para {
    text-align: justify !important;
    margin-bottom: 5%;
  }
  .elite__vision {
    margin-right: 0px !important;
  }
  .elite__about__explore {
    margin-left: 0px !important;
    margin-bottom: 5%;
  }
  .about__hr__tag {
    display: block !important;
  }
  .testimonials__box {
    width: 370px !important;
  }
  .testimonial__carousel {
    margin-bottom: 20%;
  }
}

/*===== Vertical Timeline =====*/
#conference-timeline {
  position: relative;
  max-width: 920px;
  width: 100%;
  margin: 0 auto;
}
#conference-timeline .timeline-start,
#conference-timeline .timeline-end {
  display: table;
  font-size: 18px;
  font-weight: 900;
  text-transform: uppercase;
  background: #00b0bd;
  padding: 15px 23px;
  color: #fff;
  max-width: 5%;
  width: 100%;
  text-align: center;
  margin: 0 auto;
}
#conference-timeline .conference-center-line {
  position: absolute;
  width: 0px;
  height: 80%;
  top: 0;
  left: 5%;
  margin-left: -2px;
  z-index: 0;
  border: 1px dashed #eb1c24;
  letter-spacing: 2px;
}
/* #conference-timeline .conference-timeline-content {
  padding-top: 67px;
  padding-bottom: 67px;
} */
.timeline-article {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  margin: 20px 0;
}
.timeline-article .content-left-container,
.timeline-article .content-right-container {
  max-width: 90%;
  width: 90%;
}
.timeline-article .timeline-author {
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #242424;
  text-align: right;
}
.timeline-article .content-left,
.timeline-article .content-right {
  position: relative;
  width: auto;
  /* border: 1px solid #ddd; */
  background-color: transparent;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.03);
  padding: 0.5rem 2rem;
}
.timeline-article p {
  /* margin: 0 0 0 60px; */
  font-family: "Poppins", sans-serif;
  padding: 0;
  font-weight: 400;
  color: #242424;
  font-size: 1rem;
  line-height: 32px;
  position: relative;
}
.timeline-article p span.article-number {
  position: absolute;
  font-weight: 300;
  font-size: 44px;
  top: 10px;
  left: -60px;
  color: #00b0bd;
}
.timeline-article .content-left-container {
  float: left;
}
.timeline-article .content-right-container {
  float: right;
}
.timeline-article .content-left:before,
.timeline-article .content-right:before {
  position: absolute;
  top: 20px;
  font-size: 23px;
  font-family: "FontAwesome";
  color: #fff;
}
.timeline-article .content-left:before {
  content: "\f0da";
  right: -8px;
}
.timeline-article .content-right:before {
  content: "\f0d9";
  left: -8px;
}
.timeline-article .meta-date {
  position: absolute;
  top: 0;
  left: 5%;
  width: 62px;
  height: 62px;
  margin-left: -31px;
  color: #fff;
  border: 1.5px dashed #eb1c24;
  border-radius: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.timeline-article .meta-date .date,
.timeline-article .meta-date .month {
  display: block;
  text-align: center;
  font-weight: 900;
}
.timeline-article .meta-date .date {
  font-size: 30px;
  line-height: 40px;
}
.timeline-article .meta-date .month {
  font-size: 18px;
  line-height: 10px;
}
/*===== // Vertical Timeline =====*/

/*===== Resonsive Vertical Timeline =====*/
@media only screen and (max-width: 830px) {
  #conference-timeline .timeline-start,
  #conference-timeline .timeline-end {
    margin: 0;
  }
  #conference-timeline .conference-center-line {
    margin-left: 0;
    left: 50px;
  }
  .timeline-article .meta-date {
    margin-left: 0;
    left: 20px;
  }
  .timeline-article .content-left-container,
  .timeline-article .content-right-container {
    max-width: 100%;
    width: auto;
    float: none;
    margin-left: 110px;
    min-height: 53px;
  }
  .timeline-article .content-left-container {
    margin-bottom: 20px;
  }
  .timeline-article .content-left,
  .timeline-article .content-right {
    padding: 10px 25px;
    min-height: 65px;
  }
  .timeline-article .content-left:before {
    content: "\f0d9";
    right: auto;
    left: -8px;
  }
  .timeline-article .content-right:before {
    display: none;
  }
}
@media only screen and (max-width: 400px) {
  .timeline-article p {
    margin: 0;
  }
  .timeline-article p span.article-number {
    display: none;
  }
}
