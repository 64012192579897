@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;500;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;500;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;500;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;500;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;500;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;500;700;800;900&display=swap);
*{
  margin: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  text-align: justify;
}


html, body {
  width: 100%;
  margin: 0; 
  padding: 0; 
  overflow-x: hidden;
}
/* 
.footer{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 5%;
    font-family: 'Poppins', sans-serif;
} */

.flex-style {
  display: flex;
  align-items: center;
}

.footer__left > p {
  color: #413b3b;
  font-family: "Poppins", sans-serif;
  text-align: left;
  font-size: 16px;
  line-height: 2;
}

.company-p {
  font-weight: 700;
}

.social-icons {
  display: flex;
  padding: 0 1rem;
}

.footer__active {
  color: #eb1c24 !important;
}

.footer__middle {
  margin-left: 20%;
}

.footer__middle > p {
  color: #413b3b;
  margin-bottom: 5%;
  font-family: "Poppins", sans-serif;
}

.hr__small__footer {
  border: solid #eb1c24;
  border-radius: 15%;
  border-bottom: none;
  /* width: 50%; */
  margin: 5px 100px 25px auto;
}

.footer__right {
  display: flex;
  align-items: center;
}

.footer__right > h3 {
  color: #1f1f1f;
  font-family: "Poppins", sans-serif;
}

.footer__media__icons {
  color: #413b3b !important;
  margin-bottom: 5% !important;
  margin-left: 5% !important;
}

.social__icon__footer {
  display: flex;
  padding: 0.5rem 1rem;
}

.elite__footer {
  margin-top: 0.5%;
  margin-bottom: 0.5%;
}

.footer__link__elite {
  text-decoration: none;
}

.footer__link__elite__about {
  text-decoration: none;
  color: #413b3b;
}

#email::-webkit-input-placeholder {
  color: #333333;
}

#email:-ms-input-placeholder {
  color: #333333;
}

#email::placeholder {
  color: #333333;
}

.email__sub2 {
  width: 100%;
  padding: 10px;
  border: none;
  color: #111111;
  background: rgba(0, 0, 0, 0.2);
}

.logo__image {
  width: 100%;
}

.svg_icons {
  -webkit-transform: scale(1.8);
          transform: scale(1.8);
  color: #111111;
}

#linkedin:hover {
  color: #0077b5;
}

#facebook:hover {
  color: #4267b2;
}

.footer-hr-top {
  opacity: 0.33;
  color: #eb1c24;
  background-color: #eb1c24;
  margin: 5% 0;
}

.footer-hr-bottom {
  opacity: 0.33;
  color: #eb1c24;
  background-color: #eb1c24;
  margin-top: 5% !important;
}

.elite__footer {
  padding: 0 75px;
}

.footer-1 {
  align-items: flex-start !important;
  justify-content: flex-start !important;
}

@media screen and (max-width: 992px) {
  .footer-1,
  .footer-2,
  .footer-3,
  .footer-4 {
    margin: 2rem 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .footer-1 {
    align-items: center !important;
    justify-content: center !important;
  }

  .email-col {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .elite__footer {
    padding: 0;
  }
}

.Toastify__toast-container--bottom-center {
  bottom: 1em;
  right: 0% !important;
  -webkit-transform: translateX(0vw) !important;
          transform: translateX(0vw) !important;
  top: 42% !important;
}

.flex-style {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.home__elite {
  font-family: "Poppins", sans-serif;
}

#landing-wrapper {
  padding: 0 75px !important;
}
.d-block.w-100 {
  height: 100vh;
}

.sub-heading {
  font-weight: 700;
}

.home__main {
  background-image: url("");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
}

.box__shadow__service__one {
  background-image: url("");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 30vh;
}

.box__shadow__service__two {
  display: flex;
  height: 30vh;
  align-items: center;
  justify-content: space-evenly;
}

.road__sec {
  margin-left: 2%;
}

.img__roadways {
  width: 50%;
  -webkit-transform: skewX(-11deg);
          transform: skewX(-11deg);
}

.home__logo {
  margin-top: 1%;
}

.banner__head > h1 {
  margin-bottom: 2%;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
}

.banner__head > p {
  color: #ffffff;
  font-weight: 700;
  font-size: 18px;
}

.home__explore {
  display: flex;
  align-items: center;
  background: #eb1c24;
  padding: 15px;
  border-radius: 30px;
  width: 15%;
  margin-left: 12%;
  margin-top: 3%;
  color: #ffffff !important;
}

.MuiButton-root {
  color: #ffffff !important;
}

.explore__arrow {
  color: #ffffff !important;
}

.img__icon {
  height: 60px;
  margin: 1rem;
}

.box-h1 {
  font-weight: 700;
  text-align: center;
  padding: 0.5rem;
  line-height: 1.5;
}

.box-p {
  font-weight: 400;
  text-align: center;
  padding: 0.5rem;
}

.home__section {
  margin: 3.5% 5% 5%;
  background-image: url(../../static/media/ellipse-circles.afd56f49.png);
  background-repeat: no-repeat;
  background-size: contain;
  /* display: block; */
}

.box__shadow {
  padding: 15px;
  box-shadow: 4px 3px 10px rgb(0 0 0 / 25%);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 334px;
}

.box__shadow:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  transition: all 0.5s ease-out;
}

.box__shadow > h4 {
  color: #1f1f1f;
  margin-top: 3%;
  margin-bottom: 3%;
}

.box__shadow > p {
  color: #413b3b;
}

.what__we__are {
  margin-top: 2%;
}

.what__we__are > h3 {
  text-align: center;
  color: #eb1c24;
}

.hr__small {
  border: solid #eb1c24;
  border-radius: 15%;
  border-bottom: none;
  width: 7%;
  margin: 5px auto 25px auto;
  opacity: 1 !important;
}

.what__we__are > h1 {
  color: #1f1f1f;
  font-weight: bold;
  text-align: center;
  margin-top: 1%;
  margin-bottom: 2%;
}

.section-heading {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 500;
  color: #eb1c24;
}

.section-underline {
  border: 4px solid #eb1c24 !important;
  border-radius: 3rem;
  border-bottom: none;
  width: 7% !important;
  margin: 0 auto;
  opacity: 1;
  color: #eb1c24;
}

.services-h1 {
  text-align: center;
  font-weight: 700;
  padding: 1.5rem;
}

.grid1 {
  background: #ffffff;
  box-shadow: 4px 3px 10px rgb(0 0 0 / 25%);
}

.box-row {
  position: relative;
}

.grid1:hover .polygon-img {
  -webkit-clip-path: inset(0 0 0 0);
          clip-path: inset(0 0 0 0);
  width: 200%;
}

.grid1:hover .polygon-div {
  position: absolute;
  color: #ffffff;
  background: rgb(235, 28, 36, 0.7);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: auto;
}

.grid1:hover .polygon-div h4 {
  padding: 1rem;
}

.grid1:hover .polygon-div p {
  padding: 0.75rem;
}

.grid1:hover .btn-outline-danger {
  margin: 1rem;
}

.grid1:hover .btn-outline-danger {
  color: #ffffff;
  border-color: #ffffff;
}

.polygon-img {
  width: auto;
  height: 300px;
  -webkit-clip-path: polygon(0 0, 56% 0%, 36% 100%, 0% 100%);
          clip-path: polygon(0 0, 56% 0%, 36% 100%, 0% 100%);
}

.polygon-div {
  height: 300px;
  padding: 0.5rem 0.25rem 0.5rem 0.75rem !important;
}

.polygon-div h4 {
  padding: 0.5rem;
}

.polygon-div p {
  padding: 0.25rem;
}

.read-more-black {
  text-decoration: none;
  font-size: 1.1rem;
  color: #1f1f1f;
  text-align: left;
  font-weight: 600;
  letter-spacing: 1px;
}
.about__desc {
  width: 60%;
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.what__we__are > p {
  margin-top: 3%;
  text-align: center;
  margin-left: 50px;
  margin-right: 50px;
  color: #413b3b;
}

.elite__services > h3 {
  text-align: center;
  color: #eb1c24;
  margin-top: 5%;
}

.elite__services > h1 {
  color: #1f1f1f;
  font-weight: bold;
  text-align: center;
  margin-top: 1%;
  margin-bottom: 2%;
}

.box__shadow__services {
  padding: 15px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.box__shadow__services > h4 {
  color: #00173e;
  margin-top: 3%;
  margin-bottom: 3%;
}

.elite__services {
  margin-left: 15px;
  margin-bottom: 15px;
}

.btn-outline-danger {
  border-radius: 3rem !important;
  padding: 0.5rem 1rem !important;
}

.mail__sec {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  text-align: center;
  margin-left: 150px;
  margin-right: 150px;
  margin-top: 7%;
  background-color: #ffffff;
  padding: 20px;
}

.mail__sec > p {
  color: #012766;
}

.email__icon {
  font-size: 40px !important;
}

.blog > h3 {
  text-align: center;
  color: #eb1c24;
  margin-top: 5%;
}

.blog > h1 {
  color: #1f1f1f;
  font-weight: bold;
  text-align: center;
  margin-top: 1%;
  margin-bottom: 2%;
  font-weight: bold;
}

.blog__image {
  width: 90%;
}

.blog__image__two {
  width: 100%;
  margin-bottom: 3%;
}

.blog {
  margin-left: 50px;
  margin-right: 50px;
  background-image: url(../../static/media/ellipse-circles.afd56f49.png);
  background-repeat: no-repeat;
}

.blog__one {
  display: flex;
  align-items: center;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  padding: 15px;
}

.blog__two {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  padding: 15px;
  display: flex;
  flex-direction: column;
}

.blog__desc > h4 {
  color: #1f1f1f;
}

.blog__desc__two > h4 {
  color: #1f1f1f;
}

.blog__desc > p {
  color: #413b3b;
}

.blog__desc__two > p {
  color: #413b3b;
}

/* .blog__three{
    margin-top: -15% !important;
} */

/* .MuiSvgIcon-root{
    color: #413B3B !important;
} */

video.video-react-video {
  height: 100vh !important;
}

.video-react-control-bar.video-react-control-bar-auto-hide {
  display: none !important;
}

.video__elite {
  padding-top: 1% !important;
}

button.video-react-big-play-button.video-react-big-play-button-center {
  display: none !important;
}

.video__elite {
  display: flex;
  justify-content: center;
}

@media (max-width: 768px) {
  .blog__one {
    display: flex;
    flex-direction: column !important;
  }
  .blog__image {
    width: 100% !important;
  }
}


@media screen and (max-width: 992px) {
  #landing-wrapper {
  padding: 0 !important;
}

.polygon-img {
  width: 100%;
  height: auto;
  -webkit-clip-path: none;
          clip-path: none;
}

.polygon-div {
  height: auto;
}
.box-row {
    padding: 1rem 2rem;
}

}
.subscribe {
  margin-top: 5%;
  margin-bottom: 5%;
  padding: 0 75px;
}
.flex-style-row {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.get__in__touch__head {
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(../../static/media/bg_touch.4ab9852b.png),
    linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  background-blend-mode: overlay;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 33vw;
}

a {
  text-decoration: none !important;
}

.talk {
  color: #ffffff;
  text-align: center;
  font-weight: 800;
  font-size: 1.75rem;
}
.get-in-h1 {
  color: #ffffff;
  font-weight: 800;
  text-align: center;
  line-height: 1.5;
  text-transform: uppercase;
}

.red-hr {
  width: 20%;
  height: 4px !important;
  color: #eb1c24;
  background-color: #eb1c24;
  opacity: 1;
  margin: 0 0 2rem;
}

.col-inner {
  color: #ffffff;
  background: #eb1c24;
  padding: 2rem;
  width: 80%;
}

.col-inner p {
  color: #dddddd;
  width: 90%;
}
/* .get__in__touch{
    background-image: url('bg_touch.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 70vh;
} */

.email__box {
  text-align: center;
  margin-left: 50px;
  margin-right: 50px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  background-color: #ffffff;
  color: #413b3b;
  padding: 20px;
  position: relative;
  top: -27vh;
}

.email__sub {
  width: 100%;
  padding: 10px;
  border: none;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.2);
}

.email__sub::-webkit-input-placeholder {
  color: #cccccc !important;
  opacity: 1;
}

.email__sub:-ms-input-placeholder {
  color: #cccccc !important;
  opacity: 1;
}

.email__sub::placeholder {
  color: #cccccc !important;
  opacity: 1;
}

.get-in-touch-btn {
  display: block !important;
  padding: 16px 24px !important;
  border-radius: 30px;
  color: #ffffff !important;
  border-radius: 3rem !important;
  font-size: 1rem !important;
  border-color: #ffffff !important;
}

.get-in-touch-btn:hover {
  color: black !important;
  background: white !important;
}

.email-svg {
  height: 25px;
  width: auto;
}

.svg-col {
  height: 44px;
  background: rgba(0, 0, 0, 0.2);
}

.email__box p {
  padding: 3% 10%;
}
/*New layout */

.email__elite__background {
  background-image: url(../../static/media/bg_touch.4ab9852b.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 50vh;
  -webkit-filter: grayscale(40%); /* Safari 6.0 - 9.0 */
  filter: grayscale(40%);
  -webkit-transform: skewX(-11deg);
          transform: skewX(-11deg);
}

.elite__background__text {
  color: #ffffff;
  margin-left: 8%;
  padding-top: 5%;
  font-weight: bold;
}

@media (max-width: 768px) {
  .email__get__in__touch {
    width: 70% !important;
  }
  .subscribe {
    margin-bottom: 15% !important;
  }
  .email__sub {
    width: 100%;
  }
}


@media screen and (max-width: 992px) {
.get__in__touch__head {
  height: 66.67vh;
}

}
h1,
p {
  font-family: "Poppins", sans-serif;
}

.elite__logo {
  height: auto;
  width: 150px;
}

.navbar .container-fluid {
  padding: 0 40px !important;
}

/* .navbar {
  height: 85px;
} */

.carousel-caption {
  position: absolute !important;
  right: 20% !important;
  top: 100px !important;
  left: 0 !important;
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
  text-align: center !important;
}

.home__nav {
  padding: 0 1rem;
  color: #eb1c24;
  cursor: pointer;
  text-decoration: none;
}

.home__nav:hover {
  color: #eb1c24;
}

.home__nav:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -3px;
  left: 0;
  background-color: #eb1c24;
  visibility: hidden;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  transition: all 0.15s ease-in-out 0s;
}

.active {
  color: #eb1c24;
}

.home__nav {
  display: flex;
  position: relative;
  color: #111111;
  align-items: center;
  justify-content: center;
}

.home__nav:hover:before,
.home__nav.active:before {
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.banner__head {
  /* width: 35%; */
  align-items: center;
  margin-left: 25%;
  margin-top: 7%;
  background: rgba(0, 0, 0, 0.5);
  padding: 30px;
  /* mask-image: linear-gradient(to bottom,rgba(0,0,0,1),rgba(0,0,0,0)); */
  /* z-index: -1; */
}

/* RESPONSIVE NAVBAR */

.bg-dark {
  --bs-bg-opacity: 0 !important;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
  z-index: 1090 !important;
}

.carousel {
  top: -125px;
}

.btn-lg {
  font-size: 1rem;
  border-radius: 3rem;
}

.btn-contact {
  border-radius: 3rem !important;
  background: #eb1c24 !important;
}

.home__contact__phone__one {
  text-decoration: none;
  color: #ffffff;
  z-index: 1;
}

/* .home__contact__phone__one:hover {
  color: #ffffff;
} */

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28235, 28, 36, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
}

.btn-outline-danger {
  color: #fff !important;
  border-color: #dc3545;
  border-radius: 3rem!important;
  background: #eb1c24!important;
  font-size: .875rem;
  padding: 1rem!important;
}

@media (max-width: 768px) {
  .home__contact__phone {
    width: 50% !important;
    margin-top: 1px !important;
  }
}

.hero {
  background: url(../../static/media/home-banner.28dfc21f.jpg),
    linear-gradient(rgba(0, 0, 0, 0.33), rgba(0, 0, 0, 0.33));
  background-blend-mode: overlay;
  background-size: cover;
  height: 50.51vw;
}

.container-fluid {
  padding: 0 !important;
  margin: 0;
}

/* .hero:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.2);
  height: 50.51vw;
} */

.caption {
  height: 50.51vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.caption-h1 {
  font-weight: 900;
  text-transform: uppercase;
  color: #ffffff;
  font-size: 3.5rem;
  /* text-shadow: 1px 1px black; */
  z-index: 1;
}

.caption-p {
  font-weight: 400;
  color: #ffffff;
  font-size: 1.5rem;
  z-index: 1;
  /* text-shadow: 1px 1px black; */
}

@media screen and (max-width: 768px){
  .hero {
  background: url(../../static/media/home-banner-mobile.79a75cc3.jpg),
    linear-gradient(rgba(0, 0, 0, 0.33), rgba(0, 0, 0, 0.33));
  background-blend-mode: overlay;
  background-size: cover;
  height: 150vw;
}

.caption {
  height: 150vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

}

@media screen and (max-width: 992px) {
  .home__nav {
  padding: 1rem;
  font-size: 1rem;
}
}
.banner__title {
  color: #ffffff;
  text-shadow: 2px 2px black;
}

.common__page__banner {
  padding: 0 75px;
}

.navbar .container-fluid {
  padding: 0 75px !important;
}

.page__banner__main__title {
  font-size: 70px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
}

.page__banner__nav {
  display: flex;
}

.page__banner__nav > h5 {
  margin-right: 3%;
  color: #eb1c24;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
}

.page__banner__nav > h5:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -3px;
  left: 0;
  background-color: #eb1c24;
  visibility: hidden;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  transition: all 0.15s ease-in-out gwblack;
}

.active {
  color: #eb1c24;
}

.page__banner__nav > h5 {
  display: inline-block;
  position: relative;
}

.page__banner__nav > h5:hover:before,
.page__banner__nav > h5.active:before {
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}
.route__banner__contact {
  text-decoration: none;
  color: #eb1c24;
  margin-right: 10%;
}

.route__banner__contact:hover {
  color: #eb1c24;
}

.route__banner__contact > h5:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -3px;
  left: 0;
  background-color: #eb1c24;
  visibility: hidden;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  transition: all 0.15s ease-in-out 0s;
}

.route__banner__contact > h5 {
  display: inline-block;
  position: relative;
}

.route__banner__contact > h5:hover:before,
.route__banner__contact > h5.active:before {
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.pageBanner {
  height: inherit;
}

a.hyper-login {font-size: 16px;color: #f2434a;font-weight: 600;display: inline-block;margin-left: 20px;}

@media screen and (max-width: 992px) {
  .common__page__banner {
  padding: 0;
}

.pageBanner {
  height: inherit;
}

  .page__banner__main__title {
    font-size: 8vw;
  }
  .page__banner {
    height: 50vh;
  }
}
/* .contact__details{
    
    position: absolute;
    top: 300px;
    margin-left: 10%;
} */

.contact__address__location {
  display: flex;
}

.contact__address__location > p {
  margin-left: 4%;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
}

.contact__support {
  display: flex;
}

.contact__mail {
  display: flex;
}

.contact__mail > p {
  margin-left: 4%;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
}

.contact__support > p {
  margin-left: 8%;
  color: #eb1c24;
  font-family: "Poppins", sans-serif;
}

.hr__contact {
  border: solid #ffffff;
  border-radius: 15%;
  border-bottom: none;
  width: 40%;
  opacity: 1 !important;
}

.contact__address {
  border-radius: 25px 25px 0px 0px;
  background: #eb1c24;
  padding: 20px;
  width: 400px;
  height: 200px;
}

.contact__support__details {
  border-radius: 0px 0px 0px 0px;
  background: #ffffff;
  padding: 20px;
  width: 450px;
  height: 100px;
  position: relative;
  right: 25px;
  box-shadow: 0px 0px 3px;
}

.contact__mail__details {
  border-radius: 0px 0px 25px 25px;
  background: #eb1c24;
  padding: 20px;
  width: 400px;
  height: 100px;
}

.contact__address > h4 {
  color: #ffffff;
  font-family: "Poppins", sans-serif;
}

.room__icon {
  color: #ffffff !important;
  font-size: 2.5rem !important;
}

.headset__mic {
  color: #eb1c24 !important;
  font-size: 2.5rem !important;
}

@media (max-width: 768px) {
  .contact__support__details {
    width: 430px !important;
  }
}

.form {
  margin-top: 5%;
  margin-bottom: 7.5%;
}

.phone__msg__icon {
  font-size: 3rem !important;
}

.form__head {
  display: flex;
}

.form__text {
  margin-left: 4%;
  font-family: "Poppins", sans-serif;
}

.form__text > h6 {
  font-weight: bold;
  color: #1f1f1f;
}

.form__text > p {
  color: #413b3b;
}

.contact__field {
  margin-top: 5%;
  width: 100%;
  padding: 10px;
  border-color: transparent;
  border-bottom: 2px solid #808080;
  border-radius: 5px;
  color: #413b3b;
}

.contact__field__number {
  margin-top: 5%;
  width: 100%;
  padding: 10px;
  border-color: transparent;
  border-bottom: 2px solid #808080;
  border-radius: 5px;
  color: #413b3b;
}

.contact__field__text {
  margin-top: 5%;
  width: 100%;
  padding: 10px;
  border-color: transparent;
  border-bottom: 2px solid #808080;
  border-radius: 5px;
  color: #413b3b;
  height: 125px;
  resize: none;
}

.contact__field__email {
  margin-top: 5%;
  width: 100%;
  padding: 10px;
  border-color: transparent;
  border-bottom: 2px solid #808080;
  border-radius: 5px;
  color: #413b3b;
}

.contact__button {
  background-color: #eb1c24;
  border: none;
  color: white;
  padding: 12px 40px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 6%;
  /* margin-left: 50%; */
}

/* .form__error{
    color:#eb1c24;
} */
/* 
.gmail-left {
  background: linear-gradient(
      rgba(255, 255, 255, 0.85),
      rgba(255, 255, 255, 0.85)
    ),
    url("./eliipse-circles-red-half-left.png");
  background-repeat: no-repeat;
} */
h4,
p {
  font-family: "Poppins", sans-serif;
}
.elite__services__values > h3 {
  text-align: center;
  font-family: "Poppins", sans-serif;
}

.elite__services__values > h1 {
  text-align: center;
  color: #1f1f1f;
  font-family: "Poppins", sans-serif;
}

.service__value {
  text-align: center;
  color: #413b3b;
}

.elite__logistics__services {
  margin-top: 5%;
  /* background: linear-gradient(
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    url("./eliipse-circles-red.png");
  background-repeat: no-repeat;
  background-size: contain; */
}

.logistics__service {
  height: 350px;
  width: 100%;
  border-radius: 0.5rem;
  margin: 0 auto;
  display: flex;
}

.flex-style-row {
    display: flex;
  align-items: center;
  justify-content: center;
}

.airline__service__col {
  padding: 5%;
  line-height: 2;
  text-align: justify;
}


.airline__service__col h4 {
  padding-bottom: 1%;
  line-height: 2;
}

.roadways__service {
  margin-top: 2%;
}

.service__line {
  display: none;
  color: #eb1c24;
  opacity: 1;
}

@media (max-width: 768px) {
  .service__value {
    text-align: justify !important;
    margin-bottom: 10%;
  }
  .logistics__service {
    height: auto !important;
  }
  .service__line {
    display: block;
  }
}


.airline__service__col h4 {
  text-transform: capitalize;
  font-weight: 700;
}

@media screen and (max-width: 992px) {
  .logistics__service {
    width: 100%;
  }
}
h6,
h3,
h5,
h4,
p {
  font-family: "Poppins", sans-serif;
}

.flex-style {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.flex-start-custom {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding: 0;
}
.benefits-h2 {
  font-weight: 700;
  color: #eb1c24;
}

.benefits-icon {
  width: 55%;
}

.benefits-h1 {
  font-weight: 800;
  color: #eb1c24;
  font-size: 2.5rem;
}

.benefits-box {
  padding: 1.5rem 0.75rem;
}

.benefits-h5 {
  font-weight: 700;
}

.benefits-hr {
  margin: 0.5rem 0 1rem;
  color: #eb1c24;
  background-color: #eb1c24;
  border: 0;
  border-radius: 2rem;
  opacity: 1;
  height: 6px !important;
  width: 15%;
}
.benefits-left {
  background: linear-gradient(
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.8)
    ),
    url(../../static/media/eliipse-circles-red.aa186d4c.png);
  background-repeat: no-repeat;
  background-size: cover;
}


/* .carousel-col {
  background: linear-gradient(
      rgba(255, 255, 255, 0.75),
      rgba(255, 255, 255, 0.75)
    ),
    url("./eliipse-circles-red.png");
  background-repeat: no-repeat;
  background-size: contain;
} */

.elite__aboutus > h3 {
  text-align: center;
  color: #eb1c24;
  font-family: "Poppins", sans-serif;
}

.elite__aboutus > h1 {
  text-align: center;
  color: #1f1f1f;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
}

.elite__aboutus__para {
  text-align: justify;
  color: #413b3b;
  font-family: "Poppins", sans-serif;
  padding: 2% 10%;
  font-weight: 500;
  font-size: 1.1rem;
}

.elite__vission__mission {
  margin-top: 5%;
}

.elite__vision {
  padding: 15px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  background-color: whitesmoke;
  margin-right: -25px;
}

.elite__mission {
  padding: 15px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  background-color: lightgray;
}

.elite__vision > h5 {
  font-weight: bold;
  color: #1f1f1f;
}

.elite__vision > p {
  color: #413b3b;
}

.elite__mission > h5 {
  font-weight: bold;
  color: #1f1f1f;
}

.elite__mission > p {
  color: #413b3b;
}

.elite__about__explore {
  padding: 15px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  background-color: #eb1c24;
  color: #ffffff;
  margin-left: -25px;
}

.elite__about__explore__button {
  margin-top: 15px !important;
  margin-left: 10px !important;
  margin-bottom: 15px !important;
  color: #ffffff;
  padding: 15px !important;
  border-radius: 30px !important;
  background-color: #eb1c24 !important;
  border-color: #ffffff !important;
}

.elite__about__explore__button:hover {
  background-color: #eb1c24 !important;
}

.elite__about__explore__para {
  margin-top: 10%;
}

.elite__button__explore {
  margin-bottom: 8%;
}

.about__details__img {
  height: 300px;
  border-style: solid;
  border-top-color: white;
  border-right-color: white;
  border-bottom-color: #eb1c24;
  border-left-color: #eb1c24;
  border-width: 1rem;
}

.about__details__img__sec {
  height: 300px;
  border-style: solid;
  border-top-color: #eb1c24;
  border-right-color: #eb1c24;
  border-bottom-color: white;
  border-left-color: white;
  border-width: 1rem;
}

.about__details {
  margin-top: 5%;
}

.about__details__row {
  margin-top: 5%;
}

.text__justify {
  text-align: justify;
}

.testimonials-tech {
  margin-top: 1%;
}

.testimonials-tech__box > h2 {
  font-weight: bold;
  color: #ffffff;
}

.testimonials-tech__box > h4 {
  color: #eb1c24;
}

.testimonials-tech__box > h5 {
  color: #ffffff;
}

.hr__testimonials-tech {
  border: solid #eb1c24;
  border-radius: 15%;
  border-bottom: none;
  width: 30%;
  opacity: 1 !important;
}

.testimonials-tech__box {
  background-color: #1f1f1f;
  width: auto;
  padding: 5rem;
  border-bottom-right-radius: 50%;
}

.testimonial__carousel {
  top: 25px !important;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  padding: 15px;
}

.testimonial__content {
  color: #413b3b !important;
  padding: 0.5rem 2rem 2rem 1rem;
}

.testimonial__author {
  color: #413b3b !important;
  position: relative !important;
  text-align: center !important;
  top: 0 !important;
}

.carousel-indicators {
  display: none !important;
}

.testimonial__avatar {
  margin-left: auto;
  margin-right: auto;
}

.about__hr__tag {
  color: #eb1c24;
  opacity: 1;
  margin-top: 5%;
  display: none;
}

@media (max-width: 768px) {
  .elite__aboutus__para {
    text-align: justify !important;
    margin-bottom: 5%;
  }
  .elite__vision {
    margin-right: 0px !important;
  }
  .elite__about__explore {
    margin-left: 0px !important;
    margin-bottom: 5%;
  }
  .about__hr__tag {
    display: block !important;
  }
  .testimonials-tech__box {
    width: 370px !important;
  }
  .testimonial__carousel {
    margin-bottom: 20%;
  }
}

.benefits_box {
  padding: 1rem 1.5rem;
  text-align: justify;
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.benefits_box h5 {
  padding: 1rem;
  margin-bottom: 0;
}

#box1 {
  background: #eeeeee;
}

#box2 {
  background: #dddddd;
}

#box3 {
  background: #cccccc;
}

.tech-carousel-img {
  border-bottom-right-radius: 50%;
  border-style: solid;
  border-top-color: #eb1c24;
  border-right-color: white;
  border-bottom-color: #ffffff;
  border-left-color: #eb1c24;
  border-width: 1rem;
  position: relative;
}

.tech-carousel-bg {
  background: #eb1c24;
  border-bottom-right-radius: 50%;
  padding: 0;
}

.carousel-content {
  padding: 2.5rem 1.5rem;
  box-shadow: 5px 10px 20px rgb(0 0 0 / 25%);
  background: #eeeeee;
  color: #1f1f1f;
}

.carousel-heading {
  font-weight: 700;
  font-size: 1.25rem;
}

@media screen and (max-width: 992px) {
  .benefits-left {
  background: none;
}

}
h6,
h3,
h5,
h4,
p {
  font-family: "Poppins", sans-serif;
}

.flex-style {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.about-bg {
  background: linear-gradient(
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    url(../../static/media/eliipse-circles-red.aa186d4c.png);
  background-repeat: no-repeat;
}

.elite__aboutus > h3 {
  text-align: center;
  color: #eb1c24;
  font-family: "Poppins", sans-serif;
}

.elite__aboutus > h1 {
  text-align: center;
  color: #1f1f1f;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
}

.elite__aboutus__para {
  text-align: justify;
  color: #413b3b;
  font-family: "Poppins", sans-serif;
  padding: 2% 10%;
  font-weight: 500;
  font-size: 1.1rem;
}

.elite__vission__mission {
  margin-top: 5%;
}

.elite__vision {
  padding: 15px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  background-color: whitesmoke;
  margin-right: -25px;
  height: 250px;
}

.elite__mission {
  padding: 15px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  background-color: lightgray;
  height: 250px;
}

.elite__vision > h5 {
  font-weight: bold;
  color: #1f1f1f;
}

.elite__vision > p {
  color: #413b3b;
}

.elite__mission > h5 {
  font-weight: bold;
  color: #1f1f1f;
}

.elite__mission > p {
  color: #413b3b;
}

.elite__about__explore {
  padding: 15px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  background-color: #eb1c24;
  color: #ffffff;
  margin-left: -25px;
  height: 250px;
}

.elite__about__explore__button {
  margin-top: 15px !important;
  margin-left: 10px !important;
  margin-bottom: 15px !important;
  color: #ffffff;
  padding: 15px !important;
  border-radius: 30px !important;
  background-color: #eb1c24 !important;
  border-color: #ffffff !important;
}

.elite__about__explore__button:hover {
  background-color: #eb1c24 !important;
}

.elite__about__explore__para {
  margin-top: 10%;
}

.elite__button__explore {
  margin-bottom: 8%;
}

.about__details__img {
  height: 300px;
  border-style: solid;
  border-top-color: white;
  border-right-color: white;
  border-bottom-color: #eb1c24;
  border-left-color: #eb1c24;
  border-width: 1rem;
}

.about__details__img__sec {
  height: 300px;
  border-style: solid;
  border-top-color: #eb1c24;
  border-right-color: #eb1c24;
  border-bottom-color: white;
  border-left-color: white;
  border-width: 1rem;
}

.about__details {
  margin-top: 5%;
}

.about__details__row {
  margin-top: 5%;
}

.text__justify {
  text-align: justify;
}

.testimonials {
  margin-top: 5%;
}

.testimonials__box > h2 {
  font-weight: bold;
  color: #ffffff;
}

.testimonials__box > h4 {
  color: #eb1c24;
}

.testimonials__box > h5 {
  color: #ffffff;
}

.hr__testimonials {
  border: solid #eb1c24;
  border-radius: 15%;
  border-bottom: none;
  width: 30%;
  opacity: 1 !important;
}

.testimonials__box {
  background-color: #1f1f1f;
  width: auto;
  padding: 5rem;
  border-bottom-right-radius: 50%;
}

.testimonial__carousel {
  top: 25px !important;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  padding: 15px;
}

.testimonial__content {
  color: #413b3b !important;
  padding: 0.5rem 2rem 2rem 1rem;
}

.testimonial__author {
  color: #413b3b !important;
  position: relative !important;
  text-align: center !important;
  top: 0 !important;
}

.carousel-indicators {
  display: none !important;
}

.testimonial__avatar {
  margin-left: auto;
  margin-right: auto;
}

.about__hr__tag {
  color: #eb1c24;
  opacity: 1;
  margin-top: 5%;
  display: none;
}

@media (max-width: 768px) {
  .elite__aboutus__para {
    text-align: justify !important;
    margin-bottom: 5%;
  }
  .elite__vision {
    margin-right: 0px !important;
  }
  .elite__about__explore {
    margin-left: 0px !important;
    margin-bottom: 5%;
  }
  .about__hr__tag {
    display: block !important;
  }
  .testimonials__box {
    width: 370px !important;
  }
  .testimonial__carousel {
    margin-bottom: 20%;
  }
}

/*===== Vertical Timeline =====*/
#conference-timeline {
  position: relative;
  max-width: 920px;
  width: 100%;
  margin: 0 auto;
}
#conference-timeline .timeline-start,
#conference-timeline .timeline-end {
  display: table;
  font-size: 18px;
  font-weight: 900;
  text-transform: uppercase;
  background: #00b0bd;
  padding: 15px 23px;
  color: #fff;
  max-width: 5%;
  width: 100%;
  text-align: center;
  margin: 0 auto;
}
#conference-timeline .conference-center-line {
  position: absolute;
  width: 0px;
  height: 80%;
  top: 0;
  left: 5%;
  margin-left: -2px;
  z-index: 0;
  border: 1px dashed #eb1c24;
  letter-spacing: 2px;
}
/* #conference-timeline .conference-timeline-content {
  padding-top: 67px;
  padding-bottom: 67px;
} */
.timeline-article {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  margin: 20px 0;
}
.timeline-article .content-left-container,
.timeline-article .content-right-container {
  max-width: 90%;
  width: 90%;
}
.timeline-article .timeline-author {
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #242424;
  text-align: right;
}
.timeline-article .content-left,
.timeline-article .content-right {
  position: relative;
  width: auto;
  /* border: 1px solid #ddd; */
  background-color: transparent;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.03);
  padding: 0.5rem 2rem;
}
.timeline-article p {
  /* margin: 0 0 0 60px; */
  font-family: "Poppins", sans-serif;
  padding: 0;
  font-weight: 400;
  color: #242424;
  font-size: 1rem;
  line-height: 32px;
  position: relative;
}

.sub-heading {
  font-weight: 800;
}
.timeline-article p span.article-number {
  position: absolute;
  font-weight: 300;
  font-size: 44px;
  top: 10px;
  left: -60px;
  color: #00b0bd;
}
.timeline-article .content-left-container {
  float: left;
}
.timeline-article .content-right-container {
  float: right;
}
.timeline-article .content-left:before,
.timeline-article .content-right:before {
  position: absolute;
  top: 20px;
  font-size: 23px;
  font-family: "FontAwesome";
  color: #fff;
}
.timeline-article .content-left:before {
  content: "\f0da";
  right: -8px;
}
.timeline-article .content-right:before {
  content: "\f0d9";
  left: -8px;
}
.timeline-article .meta-date {
  position: absolute;
  top: 0;
  left: 5%;
  width: 62px;
  height: 62px;
  margin-left: -31px;
  color: #fff;
  border: 1.5px dashed #eb1c24;
  border-radius: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.timeline-article .meta-date .date,
.timeline-article .meta-date .month {
  display: block;
  text-align: center;
  font-weight: 900;
}
.timeline-article .meta-date .date {
  font-size: 30px;
  line-height: 40px;
}
.timeline-article .meta-date .month {
  font-size: 18px;
  line-height: 10px;
}
/*===== // Vertical Timeline =====*/

/*===== Resonsive Vertical Timeline =====*/
@media only screen and (max-width: 830px) {
  #conference-timeline .timeline-start,
  #conference-timeline .timeline-end {
    margin: 0;
  }
  #conference-timeline .conference-center-line {
    margin-left: 0;
    left: 50px;
  }
  .timeline-article .meta-date {
    margin-left: 0;
    left: 20px;
  }
  .timeline-article .content-left-container,
  .timeline-article .content-right-container {
    max-width: 100%;
    width: auto;
    float: none;
    margin-left: 110px;
    min-height: 53px;
  }
  .timeline-article .content-left-container {
    margin-bottom: 20px;
  }
  .timeline-article .content-left,
  .timeline-article .content-right {
    padding: 10px 25px;
    min-height: 65px;
  }
  .timeline-article .content-left:before {
    content: "\f0d9";
    right: auto;
    left: -8px;
  }
  .timeline-article .content-right:before {
    display: none;
  }
}
@media only screen and (max-width: 400px) {
  .timeline-article p {
    margin: 0;
  }
  .timeline-article p span.article-number {
    display: none;
  }
}

h6,
h3,
h5,
h4,
p {
  font-family: "Poppins", sans-serif;
}

.flex-style {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

/* .about-bg {
  background: linear-gradient(
      rgba(255, 255, 255, 0.85),
      rgba(255, 255, 255, 0.85)
    ),
    url("./eliipse-circles-red.png");
  background-repeat: no-repeat;
} */



.elite__aboutus > h3 {
  text-align: center;
  color: #eb1c24;
  font-family: "Poppins", sans-serif;
}

.elite__aboutus > h1 {
  text-align: center;
  color: #1f1f1f;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
}

.elite__aboutus__para {
  text-align: justify;
  color: #413b3b;
  font-family: "Poppins", sans-serif;
  padding: 2% 10%;
  font-weight: 500;
  font-size: 1.1rem;
}

.elite__vission__mission {
  margin-top: 5%;
}

.elite__vision {
  padding: 15px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  background-color: whitesmoke;
  margin-right: -25px;
  height: 250px;
}

.elite__mission {
  padding: 15px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  background-color: lightgray;
  height: 250px;
}

.elite__vision > h5 {
  font-weight: bold;
  color: #1f1f1f;
}

.elite__vision > p {
  color: #413b3b;
}

.elite__mission > h5 {
  font-weight: bold;
  color: #1f1f1f;
}

.elite__mission > p {
  color: #413b3b;
}

.elite__about__explore {
  padding: 15px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  background-color: #eb1c24;
  color: #ffffff;
  margin-left: -25px;
  height: 250px;
}

.elite__about__explore__button {
  margin-top: 15px !important;
  margin-left: 10px !important;
  margin-bottom: 15px !important;
  color: #ffffff;
  padding: 15px !important;
  border-radius: 30px !important;
  background-color: #eb1c24 !important;
  border-color: #ffffff !important;
}

.elite__about__explore__button:hover {
  background-color: #eb1c24 !important;
}

.elite__about__explore__para {
  margin-top: 10%;
}

.elite__button__explore {
  margin-bottom: 8%;
}

.about__details__img {
  height: 300px;
  border-style: solid;
  border-top-color: white;
  border-right-color: white;
  border-bottom-color: #eb1c24;
  border-left-color: #eb1c24;
  border-width: 1rem;
}

.about__details__img__sec {
  height: 300px;
  border-style: solid;
  border-top-color: #eb1c24;
  border-right-color: #eb1c24;
  border-bottom-color: white;
  border-left-color: white;
  border-width: 1rem;
}

.about__details {
  margin-top: 5%;
}

.about__details__row {
  margin-top: 5%;
}

.text__justify {
  text-align: justify;
}

.testimonials {
  margin-top: 5%;
}

.testimonials__box > h2 {
  font-weight: bold;
  color: #ffffff;
}

.testimonials__box > h4 {
  color: #eb1c24;
}

.testimonials__box > h5 {
  color: #ffffff;
}

.hr__testimonials {
  border: solid #eb1c24;
  border-radius: 15%;
  border-bottom: none;
  width: 30%;
  opacity: 1 !important;
}

.testimonials__box {
  background-color: #1f1f1f;
  width: auto;
  padding: 5rem;
  border-bottom-right-radius: 50%;
}

.testimonial__carousel {
  top: 25px !important;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  padding: 15px;
}

.testimonial__content {
  color: #413b3b !important;
  padding: 0.5rem 2rem 2rem 1rem;
}

.testimonial__author {
  color: #413b3b !important;
  position: relative !important;
  text-align: center !important;
  top: 0 !important;
}

.carousel-indicators {
  display: none !important;
}

.testimonial__avatar {
  margin-left: auto;
  margin-right: auto;
}

.about__hr__tag {
  color: #eb1c24;
  opacity: 1;
  margin-top: 5%;
  display: none;
}

@media (max-width: 768px) {
  .elite__aboutus__para {
    text-align: justify !important;
    margin-bottom: 5%;
  }
  .elite__vision {
    margin-right: 0px !important;
  }
  .elite__about__explore {
    margin-left: 0px !important;
    margin-bottom: 5%;
  }
  .about__hr__tag {
    display: block !important;
  }
  .testimonials__box {
    width: 370px !important;
  }
  .testimonial__carousel {
    margin-bottom: 20%;
  }
}

/*===== Vertical Timeline =====*/
#conference-timeline {
  position: relative;
  max-width: 920px;
  width: 100%;
  margin: 0 auto;
}
#conference-timeline .timeline-start,
#conference-timeline .timeline-end {
  display: table;
  font-size: 18px;
  font-weight: 900;
  text-transform: uppercase;
  background: #00b0bd;
  padding: 15px 23px;
  color: #fff;
  max-width: 5%;
  width: 100%;
  text-align: center;
  margin: 0 auto;
}
#conference-timeline .conference-center-line {
  position: absolute;
  width: 0px;
  height: 80%;
  top: 0;
  left: 5%;
  margin-left: -2px;
  z-index: 0;
  border: 1px dashed #eb1c24;
  letter-spacing: 2px;
}
/* #conference-timeline .conference-timeline-content {
  padding-top: 67px;
  padding-bottom: 67px;
} */
.timeline-article {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  margin: 20px 0;
}
.timeline-article .content-left-container,
.timeline-article .content-right-container {
  max-width: 90%;
  width: 90%;
}
.timeline-article .timeline-author {
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #242424;
  text-align: right;
}
.timeline-article .content-left,
.timeline-article .content-right {
  position: relative;
  width: auto;
  /* border: 1px solid #ddd; */
  background-color: transparent;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.03);
  padding: 0.5rem 2rem;
}
.timeline-article p {
  /* margin: 0 0 0 60px; */
  font-family: "Poppins", sans-serif;
  padding: 0;
  font-weight: 400;
  color: #242424;
  font-size: 1rem;
  line-height: 32px;
  position: relative;
}
.timeline-article p span.article-number {
  position: absolute;
  font-weight: 300;
  font-size: 44px;
  top: 10px;
  left: -60px;
  color: #00b0bd;
}
.timeline-article .content-left-container {
  float: left;
}
.timeline-article .content-right-container {
  float: right;
}
.timeline-article .content-left:before,
.timeline-article .content-right:before {
  position: absolute;
  top: 20px;
  font-size: 23px;
  font-family: "FontAwesome";
  color: #fff;
}
.timeline-article .content-left:before {
  content: "\f0da";
  right: -8px;
}
.timeline-article .content-right:before {
  content: "\f0d9";
  left: -8px;
}
.timeline-article .meta-date {
  position: absolute;
  top: 0;
  left: 5%;
  width: 62px;
  height: 62px;
  margin-left: -31px;
  color: #fff;
  border: 1.5px dashed #eb1c24;
  border-radius: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.timeline-article .meta-date .date,
.timeline-article .meta-date .month {
  display: block;
  text-align: center;
  font-weight: 900;
}
.timeline-article .meta-date .date {
  font-size: 30px;
  line-height: 40px;
}
.timeline-article .meta-date .month {
  font-size: 18px;
  line-height: 10px;
}
/*===== // Vertical Timeline =====*/

/*===== Resonsive Vertical Timeline =====*/
@media only screen and (max-width: 830px) {
  #conference-timeline .timeline-start,
  #conference-timeline .timeline-end {
    margin: 0;
  }
  #conference-timeline .conference-center-line {
    margin-left: 0;
    left: 50px;
  }
  .timeline-article .meta-date {
    margin-left: 0;
    left: 20px;
  }
  .timeline-article .content-left-container,
  .timeline-article .content-right-container {
    max-width: 100%;
    width: auto;
    float: none;
    margin-left: 110px;
    min-height: 53px;
  }
  .timeline-article .content-left-container {
    margin-bottom: 20px;
  }
  .timeline-article .content-left,
  .timeline-article .content-right {
    padding: 10px 25px;
    min-height: 65px;
  }
  .timeline-article .content-left:before {
    content: "\f0d9";
    right: auto;
    left: -8px;
  }
  .timeline-article .content-right:before {
    display: none;
  }
}
@media only screen and (max-width: 400px) {
  .timeline-article p {
    margin: 0;
  }
  .timeline-article p span.article-number {
    display: none;
  }
}

.thanks-bg {
  background: linear-gradient(
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    url(../../static/media/eliipse-circles-red.aa186d4c.png);
  background-repeat: no-repeat;
  background-position: center;
}

.about img.elite__logo.flag_ship, .about a.hyper-login, .services img.elite__logo.flag_ship, .services a.hyper-login, .contact img.elite__logo.flag_ship, .contact a.hyper-login {
    display: none;
}
.privacy_policy .common__page__banner {
    display: none;
}

.privacy_policy h3 {
    margin-bottom: 30px !important;
    margin-top: 30px;
    color: #dc3545;
    font-size: 30px;
    font-weight: 600;
}

.privacy_policy h4 {
    margin-bottom: 20px !important; 
}
.scroll__to__top{
    position: fixed;
  bottom: 0.5rem;
  right: 0.5rem;
  -webkit-animation: fadeIn 700ms ease-in-out 1s both;
          animation: fadeIn 700ms ease-in-out 1s both;
  cursor: pointer;
}

.go__top__button{
    background-color: #eb1c24;
    color: #FFF;
    font-size: 30px !important;
}
