@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;500;600;700;800;900&display=swap");

.flex-style {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.home__elite {
  font-family: "Poppins", sans-serif;
}

#landing-wrapper {
  padding: 0 75px !important;
}
.d-block.w-100 {
  height: 100vh;
}

.sub-heading {
  font-weight: 700;
}

.home__main {
  background-image: url("");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
}

.box__shadow__service__one {
  background-image: url("");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 30vh;
}

.box__shadow__service__two {
  display: flex;
  height: 30vh;
  align-items: center;
  justify-content: space-evenly;
}

.road__sec {
  margin-left: 2%;
}

.img__roadways {
  width: 50%;
  transform: skewX(-11deg);
}

.home__logo {
  margin-top: 1%;
}

.banner__head > h1 {
  margin-bottom: 2%;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
}

.banner__head > p {
  color: #ffffff;
  font-weight: 700;
  font-size: 18px;
}

.home__explore {
  display: flex;
  align-items: center;
  background: #eb1c24;
  padding: 15px;
  border-radius: 30px;
  width: 15%;
  margin-left: 12%;
  margin-top: 3%;
  color: #ffffff !important;
}

.MuiButton-root {
  color: #ffffff !important;
}

.explore__arrow {
  color: #ffffff !important;
}

.img__icon {
  height: 60px;
  margin: 1rem;
}

.box-h1 {
  font-weight: 700;
  text-align: center;
  padding: 0.5rem;
  line-height: 1.5;
}

.box-p {
  font-weight: 400;
  text-align: center;
  padding: 0.5rem;
}

.home__section {
  margin: 3.5% 5% 5%;
  background-image: url("ellipse-circles.png");
  background-repeat: no-repeat;
  background-size: contain;
  /* display: block; */
}

.box__shadow {
  padding: 15px;
  box-shadow: 4px 3px 10px rgb(0 0 0 / 25%);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 334px;
}

.box__shadow:hover {
  transform: scale(1.1);
  transition: all 0.5s ease-out;
}

.box__shadow > h4 {
  color: #1f1f1f;
  margin-top: 3%;
  margin-bottom: 3%;
}

.box__shadow > p {
  color: #413b3b;
}

.what__we__are {
  margin-top: 2%;
}

.what__we__are > h3 {
  text-align: center;
  color: #eb1c24;
}

.hr__small {
  border: solid #eb1c24;
  border-radius: 15%;
  border-bottom: none;
  width: 7%;
  margin: 5px auto 25px auto;
  opacity: 1 !important;
}

.what__we__are > h1 {
  color: #1f1f1f;
  font-weight: bold;
  text-align: center;
  margin-top: 1%;
  margin-bottom: 2%;
}

.section-heading {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 500;
  color: #eb1c24;
}

.section-underline {
  border: 4px solid #eb1c24 !important;
  border-radius: 3rem;
  border-bottom: none;
  width: 7% !important;
  margin: 0 auto;
  opacity: 1;
  color: #eb1c24;
}

.services-h1 {
  text-align: center;
  font-weight: 700;
  padding: 1.5rem;
}

.grid1 {
  background: #ffffff;
  box-shadow: 4px 3px 10px rgb(0 0 0 / 25%);
}

.box-row {
  position: relative;
}

.grid1:hover .polygon-img {
  clip-path: inset(0 0 0 0);
  width: 200%;
}

.grid1:hover .polygon-div {
  position: absolute;
  color: #ffffff;
  background: rgb(235, 28, 36, 0.7);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: auto;
}

.grid1:hover .polygon-div h4 {
  padding: 1rem;
}

.grid1:hover .polygon-div p {
  padding: 0.75rem;
}

.grid1:hover .btn-outline-danger {
  margin: 1rem;
}

.grid1:hover .btn-outline-danger {
  color: #ffffff;
  border-color: #ffffff;
}

.polygon-img {
  width: auto;
  height: 300px;
  clip-path: polygon(0 0, 56% 0%, 36% 100%, 0% 100%);
}

.polygon-div {
  height: 300px;
  padding: 0.5rem 0.25rem 0.5rem 0.75rem !important;
}

.polygon-div h4 {
  padding: 0.5rem;
}

.polygon-div p {
  padding: 0.25rem;
}

.read-more-black {
  text-decoration: none;
  font-size: 1.1rem;
  color: #1f1f1f;
  text-align: left;
  font-weight: 600;
  letter-spacing: 1px;
}
.about__desc {
  width: 60%;
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.what__we__are > p {
  margin-top: 3%;
  text-align: center;
  margin-left: 50px;
  margin-right: 50px;
  color: #413b3b;
}

.elite__services > h3 {
  text-align: center;
  color: #eb1c24;
  margin-top: 5%;
}

.elite__services > h1 {
  color: #1f1f1f;
  font-weight: bold;
  text-align: center;
  margin-top: 1%;
  margin-bottom: 2%;
}

.box__shadow__services {
  padding: 15px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.box__shadow__services > h4 {
  color: #00173e;
  margin-top: 3%;
  margin-bottom: 3%;
}

.elite__services {
  margin-left: 15px;
  margin-bottom: 15px;
}

.btn-outline-danger {
  border-radius: 3rem !important;
  padding: 0.5rem 1rem !important;
}

.mail__sec {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  text-align: center;
  margin-left: 150px;
  margin-right: 150px;
  margin-top: 7%;
  background-color: #ffffff;
  padding: 20px;
}

.mail__sec > p {
  color: #012766;
}

.email__icon {
  font-size: 40px !important;
}

.blog > h3 {
  text-align: center;
  color: #eb1c24;
  margin-top: 5%;
}

.blog > h1 {
  color: #1f1f1f;
  font-weight: bold;
  text-align: center;
  margin-top: 1%;
  margin-bottom: 2%;
  font-weight: bold;
}

.blog__image {
  width: 90%;
}

.blog__image__two {
  width: 100%;
  margin-bottom: 3%;
}

.blog {
  margin-left: 50px;
  margin-right: 50px;
  background-image: url("ellipse-circles.png");
  background-repeat: no-repeat;
}

.blog__one {
  display: flex;
  align-items: center;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  padding: 15px;
}

.blog__two {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  padding: 15px;
  display: flex;
  flex-direction: column;
}

.blog__desc > h4 {
  color: #1f1f1f;
}

.blog__desc__two > h4 {
  color: #1f1f1f;
}

.blog__desc > p {
  color: #413b3b;
}

.blog__desc__two > p {
  color: #413b3b;
}

/* .blog__three{
    margin-top: -15% !important;
} */

/* .MuiSvgIcon-root{
    color: #413B3B !important;
} */

video.video-react-video {
  height: 100vh !important;
}

.video-react-control-bar.video-react-control-bar-auto-hide {
  display: none !important;
}

.video__elite {
  padding-top: 1% !important;
}

button.video-react-big-play-button.video-react-big-play-button-center {
  display: none !important;
}

.video__elite {
  display: flex;
  justify-content: center;
}

@media (max-width: 768px) {
  .blog__one {
    display: flex;
    flex-direction: column !important;
  }
  .blog__image {
    width: 100% !important;
  }
}


@media screen and (max-width: 992px) {
  #landing-wrapper {
  padding: 0 !important;
}

.polygon-img {
  width: 100%;
  height: auto;
  clip-path: none;
}

.polygon-div {
  height: auto;
}
.box-row {
    padding: 1rem 2rem;
}

}