@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;500;700;800;900&display=swap");

.banner__title {
  color: #ffffff;
  text-shadow: 2px 2px black;
}

.common__page__banner {
  padding: 0 75px;
}

.navbar .container-fluid {
  padding: 0 75px !important;
}

.page__banner__main__title {
  font-size: 70px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
}

.page__banner__nav {
  display: flex;
}

.page__banner__nav > h5 {
  margin-right: 3%;
  color: #eb1c24;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
}

.page__banner__nav > h5:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -3px;
  left: 0;
  background-color: #eb1c24;
  visibility: hidden;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: all 0.15s ease-in-out 0s;
  transition: all 0.15s ease-in-out gwblack;
}

.active {
  color: #eb1c24;
}

.page__banner__nav > h5 {
  display: inline-block;
  position: relative;
}

.page__banner__nav > h5:hover:before,
.page__banner__nav > h5.active:before {
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}
.route__banner__contact {
  text-decoration: none;
  color: #eb1c24;
  margin-right: 10%;
}

.route__banner__contact:hover {
  color: #eb1c24;
}

.route__banner__contact > h5:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -3px;
  left: 0;
  background-color: #eb1c24;
  visibility: hidden;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: all 0.15s ease-in-out 0s;
  transition: all 0.15s ease-in-out 0s;
}

.route__banner__contact > h5 {
  display: inline-block;
  position: relative;
}

.route__banner__contact > h5:hover:before,
.route__banner__contact > h5.active:before {
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.pageBanner {
  height: inherit;
}

a.hyper-login {font-size: 16px;color: #f2434a;font-weight: 600;display: inline-block;margin-left: 20px;}

@media screen and (max-width: 992px) {
  .common__page__banner {
  padding: 0;
}

.pageBanner {
  height: inherit;
}

  .page__banner__main__title {
    font-size: 8vw;
  }
  .page__banner {
    height: 50vh;
  }
}