@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
/* 
.footer{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 5%;
    font-family: 'Poppins', sans-serif;
} */

.flex-style {
  display: flex;
  align-items: center;
}

.footer__left > p {
  color: #413b3b;
  font-family: "Poppins", sans-serif;
  text-align: left;
  font-size: 16px;
  line-height: 2;
}

.company-p {
  font-weight: 700;
}

.social-icons {
  display: flex;
  padding: 0 1rem;
}

.footer__active {
  color: #eb1c24 !important;
}

.footer__middle {
  margin-left: 20%;
}

.footer__middle > p {
  color: #413b3b;
  margin-bottom: 5%;
  font-family: "Poppins", sans-serif;
}

.hr__small__footer {
  border: solid #eb1c24;
  border-radius: 15%;
  border-bottom: none;
  /* width: 50%; */
  margin: 5px 100px 25px auto;
}

.footer__right {
  display: flex;
  align-items: center;
}

.footer__right > h3 {
  color: #1f1f1f;
  font-family: "Poppins", sans-serif;
}

.footer__media__icons {
  color: #413b3b !important;
  margin-bottom: 5% !important;
  margin-left: 5% !important;
}

.social__icon__footer {
  display: flex;
  padding: 0.5rem 1rem;
}

.elite__footer {
  margin-top: 0.5%;
  margin-bottom: 0.5%;
}

.footer__link__elite {
  text-decoration: none;
}

.footer__link__elite__about {
  text-decoration: none;
  color: #413b3b;
}

#email::placeholder {
  color: #333333;
}

.email__sub2 {
  width: 100%;
  padding: 10px;
  border: none;
  color: #111111;
  background: rgba(0, 0, 0, 0.2);
}

.logo__image {
  width: 100%;
}

.svg_icons {
  transform: scale(1.8);
  color: #111111;
}

#linkedin:hover {
  color: #0077b5;
}

#facebook:hover {
  color: #4267b2;
}

.footer-hr-top {
  opacity: 0.33;
  color: #eb1c24;
  background-color: #eb1c24;
  margin: 5% 0;
}

.footer-hr-bottom {
  opacity: 0.33;
  color: #eb1c24;
  background-color: #eb1c24;
  margin-top: 5% !important;
}

.elite__footer {
  padding: 0 75px;
}

.footer-1 {
  align-items: flex-start !important;
  justify-content: flex-start !important;
}

@media screen and (max-width: 992px) {
  .footer-1,
  .footer-2,
  .footer-3,
  .footer-4 {
    margin: 2rem 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .footer-1 {
    align-items: center !important;
    justify-content: center !important;
  }

  .email-col {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .elite__footer {
    padding: 0;
  }
}

.Toastify__toast-container--bottom-center {
  bottom: 1em;
  right: 0% !important;
  transform: translateX(0vw) !important;
  top: 42% !important;
}
