@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;500;700;800;900&display=swap");

h6,
h3,
h5,
h4,
p {
  font-family: "Poppins", sans-serif;
}

.flex-style {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.flex-start-custom {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding: 0;
}
.benefits-h2 {
  font-weight: 700;
  color: #eb1c24;
}

.benefits-icon {
  width: 55%;
}

.benefits-h1 {
  font-weight: 800;
  color: #eb1c24;
  font-size: 2.5rem;
}

.benefits-box {
  padding: 1.5rem 0.75rem;
}

.benefits-h5 {
  font-weight: 700;
}

.benefits-hr {
  margin: 0.5rem 0 1rem;
  color: #eb1c24;
  background-color: #eb1c24;
  border: 0;
  border-radius: 2rem;
  opacity: 1;
  height: 6px !important;
  width: 15%;
}
.benefits-left {
  background: linear-gradient(
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.8)
    ),
    url("./eliipse-circles-red.png");
  background-repeat: no-repeat;
  background-size: cover;
}


/* .carousel-col {
  background: linear-gradient(
      rgba(255, 255, 255, 0.75),
      rgba(255, 255, 255, 0.75)
    ),
    url("./eliipse-circles-red.png");
  background-repeat: no-repeat;
  background-size: contain;
} */

.elite__aboutus > h3 {
  text-align: center;
  color: #eb1c24;
  font-family: "Poppins", sans-serif;
}

.elite__aboutus > h1 {
  text-align: center;
  color: #1f1f1f;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
}

.elite__aboutus__para {
  text-align: justify;
  color: #413b3b;
  font-family: "Poppins", sans-serif;
  padding: 2% 10%;
  font-weight: 500;
  font-size: 1.1rem;
}

.elite__vission__mission {
  margin-top: 5%;
}

.elite__vision {
  padding: 15px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  background-color: whitesmoke;
  margin-right: -25px;
}

.elite__mission {
  padding: 15px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  background-color: lightgray;
}

.elite__vision > h5 {
  font-weight: bold;
  color: #1f1f1f;
}

.elite__vision > p {
  color: #413b3b;
}

.elite__mission > h5 {
  font-weight: bold;
  color: #1f1f1f;
}

.elite__mission > p {
  color: #413b3b;
}

.elite__about__explore {
  padding: 15px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  background-color: #eb1c24;
  color: #ffffff;
  margin-left: -25px;
}

.elite__about__explore__button {
  margin-top: 15px !important;
  margin-left: 10px !important;
  margin-bottom: 15px !important;
  color: #ffffff;
  padding: 15px !important;
  border-radius: 30px !important;
  background-color: #eb1c24 !important;
  border-color: #ffffff !important;
}

.elite__about__explore__button:hover {
  background-color: #eb1c24 !important;
}

.elite__about__explore__para {
  margin-top: 10%;
}

.elite__button__explore {
  margin-bottom: 8%;
}

.about__details__img {
  height: 300px;
  border-style: solid;
  border-top-color: white;
  border-right-color: white;
  border-bottom-color: #eb1c24;
  border-left-color: #eb1c24;
  border-width: 1rem;
}

.about__details__img__sec {
  height: 300px;
  border-style: solid;
  border-top-color: #eb1c24;
  border-right-color: #eb1c24;
  border-bottom-color: white;
  border-left-color: white;
  border-width: 1rem;
}

.about__details {
  margin-top: 5%;
}

.about__details__row {
  margin-top: 5%;
}

.text__justify {
  text-align: justify;
}

.testimonials-tech {
  margin-top: 1%;
}

.testimonials-tech__box > h2 {
  font-weight: bold;
  color: #ffffff;
}

.testimonials-tech__box > h4 {
  color: #eb1c24;
}

.testimonials-tech__box > h5 {
  color: #ffffff;
}

.hr__testimonials-tech {
  border: solid #eb1c24;
  border-radius: 15%;
  border-bottom: none;
  width: 30%;
  opacity: 1 !important;
}

.testimonials-tech__box {
  background-color: #1f1f1f;
  width: auto;
  padding: 5rem;
  border-bottom-right-radius: 50%;
}

.testimonial__carousel {
  top: 25px !important;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  padding: 15px;
}

.testimonial__content {
  color: #413b3b !important;
  padding: 0.5rem 2rem 2rem 1rem;
}

.testimonial__author {
  color: #413b3b !important;
  position: relative !important;
  text-align: center !important;
  top: 0 !important;
}

.carousel-indicators {
  display: none !important;
}

.testimonial__avatar {
  margin-left: auto;
  margin-right: auto;
}

.about__hr__tag {
  color: #eb1c24;
  opacity: 1;
  margin-top: 5%;
  display: none;
}

@media (max-width: 768px) {
  .elite__aboutus__para {
    text-align: justify !important;
    margin-bottom: 5%;
  }
  .elite__vision {
    margin-right: 0px !important;
  }
  .elite__about__explore {
    margin-left: 0px !important;
    margin-bottom: 5%;
  }
  .about__hr__tag {
    display: block !important;
  }
  .testimonials-tech__box {
    width: 370px !important;
  }
  .testimonial__carousel {
    margin-bottom: 20%;
  }
}

.benefits_box {
  padding: 1rem 1.5rem;
  text-align: justify;
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.benefits_box h5 {
  padding: 1rem;
  margin-bottom: 0;
}

#box1 {
  background: #eeeeee;
}

#box2 {
  background: #dddddd;
}

#box3 {
  background: #cccccc;
}

.tech-carousel-img {
  border-bottom-right-radius: 50%;
  border-style: solid;
  border-top-color: #eb1c24;
  border-right-color: white;
  border-bottom-color: #ffffff;
  border-left-color: #eb1c24;
  border-width: 1rem;
  position: relative;
}

.tech-carousel-bg {
  background: #eb1c24;
  border-bottom-right-radius: 50%;
  padding: 0;
}

.carousel-content {
  padding: 2.5rem 1.5rem;
  box-shadow: 5px 10px 20px rgb(0 0 0 / 25%);
  background: #eeeeee;
  color: #1f1f1f;
}

.carousel-heading {
  font-weight: 700;
  font-size: 1.25rem;
}

@media screen and (max-width: 992px) {
  .benefits-left {
  background: none;
}

}