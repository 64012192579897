.privacy_policy .common__page__banner {
    display: none;
}

.privacy_policy h3 {
    margin-bottom: 30px !important;
    margin-top: 30px;
    color: #dc3545;
    font-size: 30px;
    font-weight: 600;
}

.privacy_policy h4 {
    margin-bottom: 20px !important; 
}