@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;500;700;800;900&display=swap");

h1,
p {
  font-family: "Poppins", sans-serif;
}

.elite__logo {
  height: auto;
  width: 150px;
}

.navbar .container-fluid {
  padding: 0 40px !important;
}

/* .navbar {
  height: 85px;
} */

.carousel-caption {
  position: absolute !important;
  right: 20% !important;
  top: 100px !important;
  left: 0 !important;
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
  text-align: center !important;
}

.home__nav {
  padding: 0 1rem;
  color: #eb1c24;
  cursor: pointer;
  text-decoration: none;
}

.home__nav:hover {
  color: #eb1c24;
}

.home__nav:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -3px;
  left: 0;
  background-color: #eb1c24;
  visibility: hidden;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: all 0.15s ease-in-out 0s;
  transition: all 0.15s ease-in-out 0s;
}

.active {
  color: #eb1c24;
}

.home__nav {
  display: flex;
  position: relative;
  color: #111111;
  align-items: center;
  justify-content: center;
}

.home__nav:hover:before,
.home__nav.active:before {
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.banner__head {
  /* width: 35%; */
  align-items: center;
  margin-left: 25%;
  margin-top: 7%;
  background: rgba(0, 0, 0, 0.5);
  padding: 30px;
  /* mask-image: linear-gradient(to bottom,rgba(0,0,0,1),rgba(0,0,0,0)); */
  /* z-index: -1; */
}

/* RESPONSIVE NAVBAR */

.bg-dark {
  --bs-bg-opacity: 0 !important;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
  z-index: 1090 !important;
}

.carousel {
  top: -125px;
}

.btn-lg {
  font-size: 1rem;
  border-radius: 3rem;
}

.btn-contact {
  border-radius: 3rem !important;
  background: #eb1c24 !important;
}

.home__contact__phone__one {
  text-decoration: none;
  color: #ffffff;
  z-index: 1;
}

/* .home__contact__phone__one:hover {
  color: #ffffff;
} */

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28235, 28, 36, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
}

.btn-outline-danger {
  color: #fff !important;
  border-color: #dc3545;
  border-radius: 3rem!important;
  background: #eb1c24!important;
  font-size: .875rem;
  padding: 1rem!important;
}

@media (max-width: 768px) {
  .home__contact__phone {
    width: 50% !important;
    margin-top: 1px !important;
  }
}

.hero {
  background: url("./home-banner.jpg"),
    linear-gradient(rgba(0, 0, 0, 0.33), rgba(0, 0, 0, 0.33));
  background-blend-mode: overlay;
  background-size: cover;
  height: 50.51vw;
}

.container-fluid {
  padding: 0 !important;
  margin: 0;
}

/* .hero:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.2);
  height: 50.51vw;
} */

.caption {
  height: 50.51vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.caption-h1 {
  font-weight: 900;
  text-transform: uppercase;
  color: #ffffff;
  font-size: 3.5rem;
  /* text-shadow: 1px 1px black; */
  z-index: 1;
}

.caption-p {
  font-weight: 400;
  color: #ffffff;
  font-size: 1.5rem;
  z-index: 1;
  /* text-shadow: 1px 1px black; */
}

@media screen and (max-width: 768px){
  .hero {
  background: url("./home-banner-mobile.jpg"),
    linear-gradient(rgba(0, 0, 0, 0.33), rgba(0, 0, 0, 0.33));
  background-blend-mode: overlay;
  background-size: cover;
  height: 150vw;
}

.caption {
  height: 150vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

}

@media screen and (max-width: 992px) {
  .home__nav {
  padding: 1rem;
  font-size: 1rem;
}
}